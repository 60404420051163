import { factory, getAuthorization } from 'api/base';

import { RequestMethod } from 'utils/constants';

export enum PaymentStatus {
  SUCCESS = 1,
  REJECTED = 2,
  IN_PROGRESS = 3,
  RoutingError = 54,
}

export enum PaymentType {
  CARD = 'card',
  IBAN = 'iban',
}

export type MethodsMap = {
  [name: string]: {
    [lang: string]: string;
  };
};

export type MethodsList = {
  name: string;
  minLimit: number | null;
  displayName: {
    [lang: string]: string;
  };
}[];

export interface SelectMethodPaySession {
  amount: number;
  recipient?: string;
  recipientName?: string;
  currency: {
    alias: string;
    code: number;
  };
  description: string;
  expiresAt: string;
  expiresIn: number;
  failedUrl: string;
  methodSent: boolean;
  methods: MethodsMap | MethodsList;
  paymentId: string;
  returnUrl: string;
  status: PaymentStatus;
  successUrl: string;
  type: PaymentType;
}

export const getSelectMethodPaySession = factory(
  (r) => (session: string) =>
    r<SelectMethodPaySession>(
      RequestMethod.get,
      `/select_method/session`,
      undefined,
      {
        headers: getAuthorization(session),
      }
    )
);

export interface UpdatedSelectMethodPaySession {
  card: string;
  cardHolder: string;
  status: PaymentStatus;
}

export const updateSelectMethodPaySession = factory(
  (r) => (session: string, method: string) =>
    r<UpdatedSelectMethodPaySession>(
      RequestMethod.post,
      `/select_method/session`,
      {
        method,
      },
      {
        headers: getAuthorization(session),
      }
    )
);
