import React from 'react';

import { useTheme } from '@involve-software/uikit';

import { LanguageDropdown } from 'components/LanguageDropdown';

import { getMirroringConfigWithDefault } from 'utils/mirroring';

import * as Styled from './index.styled';

const Header = () => {
  const { images } = useTheme();

  return (
    <Styled.Header>
      <Styled.HeaderLogo>
        {images.logo.path && (
          <img
            src={getMirroringConfigWithDefault('logoDesktop', images.logo.path)}
            alt="logo"
          />
        )}
      </Styled.HeaderLogo>
      <LanguageDropdown />
    </Styled.Header>
  );
};

export default Header;
